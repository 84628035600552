import axios, { AxiosRequestConfig } from "axios";
import { Toast } from "vant";
import router from "@/router";
import Vue from "vue";
// let isknow = false;
const service = axios.create({
  // TODO
  baseURL: process.env.NODE_ENV == "dev" ? "/devapi" : process.env.VUE_APP_PORT, // url = base url + request url
  // 登录单独form提交
  // transformRequest: [function (data) {
  //     // Do whatever you want to transform the data
  //     let ret = ''
  //     for (let it in data) {
  //       ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  //     }
  //     return ret
  // }],
  withCredentials: true // send cookies when cross-domain requests
});

// http request 请求拦截器
service.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    // const jwtToken = getLocalStorage('JWT_TOKEN')
    // if (jwtToken) {
    //   config.headers.Authorization = `token ${jwtToken}`
    // }
    const configURL = config.url;
    const configPage = config.params || {};
    const isconfigPage = configPage.hasOwnProperty('page');


    
    // config.url = 'http://120.25.24.20:81/api'+config.url
    // 以'/order/query'开头的请求 不加提示  以/file开头的请求不加提示

        if ( configURL && (configURL.indexOf("/file") >= 0 || configURL.indexOf("/order/query") >= 0 || configURL.indexOf("/face/faceVerify") >= 0 || (isconfigPage && configPage.page >0))) {
              // 手动清除 Loading
             Vue.prototype.$Loading.close()
        }else if(configURL && (configURL.indexOf("/oauth/token") >= 0)){
             Vue.prototype.$Loading.show({text:'登录中...'})
        }else{
             Vue.prototype.$Loading.show({text:'加载中...'})
        }
        if (
           configURL &&
              (configURL.indexOf("/sys/user/agreement/") >= 0 ||
               configURL.indexOf("/captcha/SIGNIN/") >= 0 ||
               configURL.indexOf("/account") >= 0 ||
               configURL.indexOf("/captcha/FORGOT/") >= 0)
            ) {
            } else {
              const overlogin = localStorage.getItem("token") || "";
              config.headers.Authorization = "Bearer " + overlogin;
            }
    // if(overlogin){
    // }else{

    // }
    // debugger

    // console.log(config);

    return config;
  },
  err => {
         // 手动清除 Loading
        Vue.prototype.$Loading.close()
        return Promise.reject(err);
  }
);

// http response 响应拦截器
service.interceptors.response.use(
  response => {
        // 手动清除 Loading
        Vue.prototype.$Loading.close()
    return response.data;
  },
  async error  => {
            // 手动清除 Loading
        Vue.prototype.$Loading.close()
    if (error.response) {
      switch (error.response.status) {
        case 403:
          return Promise.reject(error);
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          // UserModule.logout()
          // if (isknow) {
          //   return false;
          // }
          // isknow = true;
          const refresh_token = localStorage.getItem("refresh_token");
          // console.log("Token 失效，请重新登录");
          const loginForm = {
            scope: "write",
            grant_type: "refresh_token",
            client_secret: JSON.parse(process.env.VUE_APP_MY_PARMA).password,
            client_id: JSON.parse(process.env.VUE_APP_MY_PARMA).username,
            refresh_token: refresh_token || ""
          };
          const auth = {
            username: JSON.parse(process.env.VUE_APP_MY_PARMA).username,
            password: JSON.parse(process.env.VUE_APP_MY_PARMA).password
          };
          const headers_ = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          };
          const transformRequest = [
            function(data:any) {
              let ret = "";
              for (const it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            }
          ];
          const baseurl = process.env.NODE_ENV == "dev" ? "/devapi" : process.env.VUE_APP_PORT;
          // TODO
           try{
            let res =  await  axios.post(baseurl + "/oauth/token", loginForm, {
              auth,
              headers:headers_,
              transformRequest
            })
            // console.log("res",res);
            localStorage.removeItem("token")
            localStorage.removeItem("refresh_token")
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            
            let newRes = await service(error.config)
            return newRes
           }catch{
              // console.log(router.currentRoute.fullPath);
              // 登出七鱼
                Vue.prototype.fishkefu.logout()
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("userinfo");
                localStorage.removeItem("idcrad");
                Toast.fail("登录超时，请重新登录。");
                router.replace({
                  path: "/login",
                  query: { redirect: router.currentRoute.fullPath }
                });
             
            };
          // localStorage.removeItem('token')
          // console.log(router.currentRoute.fullPath)
          // router.replace({
          //   path: '/login',
          //   query: { redirect: router.currentRoute.fullPath }
          // })
          // Toast.fail('Token 失效，请重新登录。')
          break;
        default:        
          // Toast.fail(error.response.data?.debug?error.response.data.debug :error.response.data.msg || "网络错误，请稍后再试");
          Toast.fail(error.response.data.debug || error.response.data.msg || "网络错误，请稍后再试");

          break;
      }
    } else {
      Toast.fail("服务器错误，请重试。");
    }
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);

// 响应结构接口定义
export interface IAxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
}

export default {
  // T 表示API返回的 类型声明
  get<T>(
    url: string,
    params: any = {},
    options: AxiosRequestConfig = {}
  ): Promise<IAxiosResponse<T>> {
    return service.get(url, {
      ...options,
      params
    });
  },

  post<T>(
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {}
  ): Promise<IAxiosResponse<T>> {
    return service.post(url, data, {
      ...options
    });
  },

  put<T>(
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {}
  ): Promise<IAxiosResponse<T>> {
    return service.put(url, data, {
      ...options
    });
  },

  delete<T>(
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {}
  ): Promise<IAxiosResponse<T>> {
    return service.delete(url, {
      ...options
    });
  },

  patch<T>(
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {}
  ): Promise<IAxiosResponse<T>> {
    return service.patch(url, data, {
      ...options
    });
  }
};
